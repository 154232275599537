<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 21 30"
  >
    <path
      fill="currentColor"
      d="M10.6 5c-1 0-2 .3-2.8.8a5.2 5.2 0 0 0-.8 7.8 5 5 0 0 0 5.5 1.2 5 5 0 0 0 2.2-1.9 5.2 5.2 0 0 0-.6-6.4c-1-1-2.2-1.5-3.5-1.5Zm0 8.4a3 3 0 0 1-1.8-.6 3.4 3.4 0 0 1-.5-5.1 3.2 3.2 0 0 1 3.5-.7c.6.3 1.1.7 1.5 1.2a3.4 3.4 0 0 1-.4 4.2c-.6.6-1.4 1-2.3 1Z"
    />
    <path
      fill="currentColor"
      d="M10.6.8a10 10 0 0 0-7.2 3c-2 2-3 4.6-3 7.4 0 4.2 2.3 7.6 4 10.2l.2.4c1.7 2.5 3.5 4.9 5.3 7.1l.7.8.7-.8c1.9-2.2 3.6-4.6 5.3-7l.3-.5c1.6-2.6 4-6 4-10.2-.1-2.8-1.2-5.4-3.1-7.4-2-2-4.5-3-7.2-3Zm4.8 19.5-.3.5a72.1 72.1 0 0 1-4.5 6.1 83 83 0 0 1-4.5-6.1l-.3-.5C4.2 18 2 14.8 2 11.2A8.9 8.9 0 0 1 4.6 5a8.5 8.5 0 0 1 6-2.5 8.3 8.3 0 0 1 6 2.5 8.7 8.7 0 0 1 2.5 6.2c0 3.6-2.1 6.8-3.7 9.1Z"
    />
  </svg>
</template>
